<template>
  <div class="ding-talk">
    <div>
      <div class="logo-info">
        <img class="logo-img" src="./images/logo.png" alt="">
        <p>网页版 MeetingIoT 申请使用钉钉登录</p>
        <p>请确认使用本人账号登录</p>
      </div>
      <div class="ding-talk-btn">
        <template v-if="clientToken">
          <van-button type="primary" @click="sureLogin">确认登录</van-button>
          <div class="cancel">
            <span @click="cancelLogin">取消登录</span>
          </div>
        </template>
        <van-button v-else type="primary" @click="scanCode">扫码登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast  } from 'vant'
import * as dd from 'dingtalk-jsapi'
import { DingLogin } from '@/api/dingding'
document.title = 'MeetingIoT'
export default {
  name: "DingTalk",
  components: {
    
  },
  data() {
    return {
      corpid: this.$route.query.corpid,
      clientToken: this.$route.query.clientToken || '',
      authCode: '',
      result: ''
    }
  },
  mounted() {
    // 初始化
    this.init();
  },
  methods: {
    /**
     * @description 初始化
     */
    init() {
      let _this = this
      dd.ready(function() {
        dd.runtime.permission.requestAuthCode({
          corpId: _this.corpid, 
          onSuccess: function (info) {
            _this.authCode = info.code
          }
        });
      });
    },
    /**
     * @description 扫一扫
     */
    scanCode () {
      let _this = this
      dd.biz.util.scan({
        type: 'qrCode',
        onSuccess: (res) => {
          let result = _this.getRequest(res.text)
          _this.clientToken = result.clientToken
        },
        onFail : function(err) {

        }
      })
    },
    /**
     * @description 登录确认
     */
    sureLogin() {
      DingLogin({
        authCode: this.authCode,
        corpId: this.corpid,
        clientToken: this.clientToken
      }).then(res => {
        this.clientToken = ''
        if (res.returncode === 200) {
          Toast('登录成功')
        } else {
          Toast(res.message)
        }
      })
    },
    /**
     * @description 取消登录
     */
    cancelLogin() {
      this.clientToken= ''
    },
    /**
     * @description 获取url中"?"符后的字串
     */
    getRequest(url) {
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.split('?')[1];
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    }
  }
};
</script>

<style lang="scss">
.ding-talk {
  text-align: center;
  padding: 30px 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    padding-bottom: 100px;
  }
  .logo-info {
    font-size: 14px;
    text-align: center;
    .logo-img {
      width: 70px;
      margin-bottom: 50px;
    }
    p {
      line-height: 20px;
    }
  }
  .ding-talk-btn {
    padding-top: 50px;
    & > button {
      width: 100%;
    }
    .cancel {
      color: #0073E6;
      text-align: center;
      line-height: 40px;
      text-align: center;
      padding-top: 10px;
      & > span {
        display: inline-block;
        padding: 0 20px;
      }
    }
  }
  .van-button--primary {
    background-color: #0073E6;
    border-color: #0073E6;
  }
}
</style>