import request from '@/utils/request'

let baseURL = '/tenant'
let query = getRequest(location.search)

if (query.profile === 'preonline') {
    baseURL = 'https://preview-guider-h5.mushiapp.com/tenant'
}

// 获取建筑下楼层及分区数据
export function DingLogin(data) {
    return request({
        baseURL: baseURL,
        url: '/dingding/execDingLogin',
        method: 'post',
        data
    })
}


function getRequest(url) {
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.split('?')[1];
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}